import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google'; // Import GoogleOAuthProvider
import './App.css';

// Pages
import Home from './pages/home/home';
import BlogsList from './pages/blog/blogsList';
import BlogDetails from './pages/blog/blogDetails';
import BookADemo from './pages/bookademo/bookADemo';
import GetApp from './pages/getapp/getApp';
import Pricing from './pages/pricing/pricing';
import Privacyplocy from './pages/privacypolicy/privacypolicy';
import Contact from './pages/contactus/contact';

// Components
import Header from './components/header';
import Footer from './components/footer/footer';
import ScrollToTop from './ScrollToTop';

const AppContent = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname !== '/profile' && <Header />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blog" element={<BlogsList />} />
        <Route path="/blog/:id" element={<BlogDetails />} />
        <Route path="/demo_request" element={<BookADemo />} />
        <Route path="/get_app" element={<GetApp />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/privacy-policy" element={<Privacyplocy />} />
        <Route path="/contact" element={<Contact />} />
        {/* Catch-all route for unwanted links */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer />
    </>
  );
};

const App = () => (
  <GoogleOAuthProvider clientId="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 ">
    <Router>
      <ScrollToTop />
      <AppContent />
    </Router>
  </GoogleOAuthProvider>
);

export default App;
