import React from 'react'
import './style.css';

function Privacyplocy() {
  return (
    <>
        <div className='innerpage-headerbanner'>
            <h1>Privacy Policy</h1>
        </div>

        <div className='container'>
          <div className='privacy-policy-content'>
            <p>Welcome to MyRollCallPro. We are committed to safeguarding your privacy and ensuring a secure experience when using our attendance system application and web interface. This Privacy Policy outlines how we collect, use and protect your information.</p>
            <h3>Information We Collect</h3>
            <h5>1. Personal Information</h5>
            <div className='options'>
              <h4>1.1. For Students</h4>
              <ul>
                <li><strong>Name</strong> : To uniquely identify each student. </li>
                <li><strong>Email Address</strong> : For communication and notifications. </li>
                <li><strong>Phone Number</strong> : To share OTP and alerts.  </li>
                <li><strong>Location Data</strong> : Collected once at the start of the session and after 15 minutes to verify accurate tracking. </li>
                <li><strong>Parent/Guardian Contact Details</strong> : For notifications and updates. </li>
                <li><strong>Real-Time Photograph-</strong> Used for facial recognition during attendance. This data may be stored for verification and security purposes. </li>
              </ul>
              <h4>1.2 For Teachers</h4>
              <ul>
                <li><strong>Name</strong> : To uniquely identify each educator. </li>
                <li><strong>Email Address</strong> : For communication and system access. </li>
                <li><strong>Phone Number</strong> : To send important updates. </li>
                <li><strong>Location Data</strong> : Collected at the start of each session for attendance verification. </li>
              </ul>
            </div>

            <div className='options'>
              <h5>2. Usage Information </h5>
              <ul>
                <li><strong>IP Addresses</strong> : To monitor and improve service delivery.</li>
                <li><strong>Browser Type and Operating System</strong> : To optimise our platform for different environments. </li>
                <li><strong>Referring URLs and Access Times</strong> : To analyse usage patterns and improve user experience.</li>
                <li><strong>Device Information</strong> : To ensure compatibility and enhance security. </li>
              </ul>
            </div>
            
            <div className='options'>
              <h3>How We Use Your Information </h3>
              <ul className='number'>
                <li><strong>User Authentication</strong> : Verifies your identity via OTP (One-Time Password) sent to your email and phone number, enhancing security.</li>
                <li><strong>System Improvement</strong> : Analyses data to refine our services and user experience.   </li>
                <li><strong>Notifications</strong> : Sends updates to parents/guardians about attendance and relevant matters, ensuring timely communication.  </li>
                <li><strong>Presence Verification</strong> : Utilises facial recognition technology and location details to authenticate attendance, ensuring accurate records and secure access. </li>
              </ul>
            </div>

            <div className='options'>
              <h3>Sharing of Information </h3>
              <ul className='number'>
                <li><strong>Third-Party Services</strong> We may share necessary information with trusted third-party service providers to operate and maintain our system, including cloud storage and communication services. </li>
                <li><strong>Legal Compliance</strong> We may disclose your information if required by law or to protect our rights, safety, or the safety of others. </li>
              </ul>
            </div>

            <div className='options'>
              <h3>Data Security</h3>
              <ul className='number'>
                <li><strong>Encryption</strong> We employ robust encryption methods to secure data both in transit and at rest. </li>
                <li><strong>Access Control</strong> Personal information is accessible only to authorized personnel who require it for their duties.</li>
              </ul>
            </div>

            <div className='options'>
              <h3>Your Choices</h3>
              <ul className='number'>
                <li><strong>Access and Update</strong> You have the right to access and update your personal information through the settings in our system. </li>
                <li><strong>Opt-Out</strong> You may choose to opt out of non-essential communications and notifications.</li>
              </ul>
            </div>

            <div className='options'>
              <h3>Children's Privacy</h3>
              <p>Our system is intended for use by individuals aged 20 and above. We do not knowingly collect or solicit personal information from individuals under this age without parental consent. </p>
            </div>

            <div className='options'>
              <h3>Policy Updates </h3>
              <p>We may update this Privacy Policy periodically to reflect changes in our practices, services, or legal requirements. Any updates will be posted on our website or app. </p>
              <p>Continued use of our services following such changes signifies your acceptance of the revised policy. </p>
              <p>Thank you for choosing MyRollCall.Pro. We are committed to maintaining the highest standards of privacy and data security.  </p>
            </div>

            
          </div>
        </div>
    
    </>
  )
}

export default Privacyplocy