import React from 'react'
import './style.css';
import { Helmet } from 'react-helmet';


function TrunStudent() {

  return (
    <>
    <Helmet>
        <meta name="h2" content="Revolutionise Administrative Efficiency" />
        <meta
          name="h2"
          content="Turn Student Attendance Register into an Analytical Asset"
        />
        <meta name="h3" content="Simplify Daily Operations, Amplify Impact" />
        <meta name="h3" content="Streamlined Processes Informed Decision Making Cost Reduction Scalable Integration" />
        
    
      </Helmet>
      <section className='section TrunStudentSection'>
        <div className='container'>
          
          <div className='col2'>
            <div className='col6'>
            <h2 className='section_heading  revolutionise_heading'>Revolutionise Administrative Efficiency</h2>
              <h2 className='turn_student_heading'>
                Turn Student <strong>Attendance Register</strong> into an Analytical Asset
              </h2>
            </div>
            <div className='col6 simplify_section'>
              <h3>Simplify Daily Operations, Amplify Impact</h3>
              <ul>
                <li><span>Streamlined Processes :</span> Simplify attendance tracking and administrative tasks to allow educators to focus on student learning.</li>
                <li><span>Informed Decision Making :</span> Access real-time data for identifying trends and get insights about students, teachers, and other KPIs for administrative functions.</li>
                <li><span>Cost Reduction :</span> Allocate resources more effectively, maximizing institutional outcomes in a cost-efficient way.</li>
                <li><span>Scalable Integration :</span> Seamlessly integrate with existing systems and expand as needed.</li>
              </ul>
                {/* <button className="globel-button">
                    <span>Explore Our Administrative Solutions</span>
                    <i><svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 6L16 6M16 6L11 1M16 6L11 11" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg></i>
                </button> */}
            </div>
          </div>
        </div>
    </section>
    </>
  
  )
}

export default TrunStudent