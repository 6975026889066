import React from 'react'
import leftGraphic from '../../assets/images/your-smart-attendance-app.svg';
import lightShap from '../../assets/images/light-shap.svg';
import rightLineShap from '../../assets/images/right-line-shap.svg';
import { Helmet } from 'react-helmet';
import './style.css';

function AboutSection() {
  return (
<>

<Helmet>
    <meta name="h2" content="Your Smart Attendance App" />
    <meta
      name="description"
      content="Manual attendance processes are prone to errors, leading to inaccurate records and added administrative work. Valuable time that should be used to enhance learning is wasted. "
    />
  </Helmet>

    <section id='about' className='section'>
      <span className='lightShap'><img src={lightShap} alt=''/></span>
      <div className='container'>
        <div className='about-section'>
          <div className='left_image'>
            <img src={leftGraphic} alt=''/>
          </div>
          <div className='about_content'>
            <h2 className='section_heading'>Your Smart <span>Attendance App</span></h2>
            <p>Manual attendance processes are prone to errors, leading to inaccurate records and added administrative work. Valuable time that should be used to enhance learning is wasted. </p>
          </div>
        </div>
      </div>
      <span className='rightLineShap'><img src={rightLineShap} alt=''/></span>
    </section>
</>
 
  )
}

export default AboutSection