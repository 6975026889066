import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';


function BookADemo() {
  useEffect(() => {
    // Change body background color when the component mounts
    document.body.style.backgroundColor = '#f4f4f4'; // Light blue color

    // Reset body background color when the component unmounts
    return () => {
        document.body.style.backgroundColor = ''; // Default color
    };
}, []);

  return (
    <>
         <Helmet>
        <title>Request a Demo - Book Your Demo Now</title>
        <meta name="h1" content="Request a Demo" />
        <meta name="description" content="Book a demo today to explore the features and benefits of our solutions tailored to your needs." />
      </Helmet>
      <div className='innerpage-headerbanner'>
        <h1>Request a <span>Demo</span></h1>
      </div>
      <iframe
          src="https://outlook.office365.com/book/SkaeosInquiry@skyoindia.com/"
          style={{
              width: '100%',
              height: '1880px', // Full viewport height
              border: 'none', // Removes border
          }}
          title="Embedded Page"
          allowFullScreen
      ></iframe>
    </>
  );
}

export default BookADemo;
