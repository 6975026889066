/* global gtag */
import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation } from 'react-router-dom';
import videoIcon from '../../assets/images/video-icon.svg';
import staricon from '../../assets/images/star-icon.svg';
import crosicon from '../../assets/images/cross-icon.svg';
import downarrow from '../../assets/images/down-arrow.svg';
import MrcpVideo from '../../assets/images/mrcp-video.mp4';
import videoCover from '../../assets/images/video-cover.jpg';

import './style.css';

function Banner() {
  const location = useLocation(); // Get the current path
  const [modalClass, setModalClass] = useState('hide');
  const videoRef = useRef(null);

  useEffect(() => {
    if (modalClass === 'show' && videoRef.current) {
      videoRef.current.play();
    } else if (modalClass === 'hide' && videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0; // Reset video to start
      videoRef.current.setAttribute('poster', videoCover); // Reset the poster
    }
  }, [modalClass]);

  const handleClose = () => {
    setModalClass('hide');
  };

  const handleClickOutside = (event) => {
    if (event.target.closest('.modal-content') !== document.getElementById('modal')) {
      handleClose();
    }
  };

  const trackEvent = (elementId) => {
    const currentPath = location.pathname;
    const formattedPath = currentPath.replace("/", "").replace(/^$/, "home_banner");
    const eventName = `${formattedPath}_${elementId}`;
    const eventData = {
      page_location: currentPath,
      page_title: document.title,
      element_id: elementId,
    };

    gtag("event", eventName, eventData);
  };

  const handleLinkClick = (to, elementId) => {
    setModalClass('show');
    trackEvent(elementId);
  };

  return (
    <>
      {/* Dynamic Helmet for Meta Tags */}
      <Helmet>
        <title>Transform Daily Attendance - MyRollCallPro</title>
        <meta
          name="description"
          content="Simplify operations, enhance productivity, and reduce costs with MyRollCallPro. Save up to two months of curricular time annually."
        />
        <meta property="og:title" content="Transform Daily Attendance - MyRollCallPro" />
        <meta property="og:description" content="Simplify operations, enhance productivity, and reduce costs with MyRollCallPro." />
        <meta property="og:image" content={videoCover} />
      </Helmet>

      <div className="main-banner">
        <span className="staricon">
          <img src={staricon} alt="Star Icon" />
        </span>
        <h1 className="fade-up">
          Transform Daily Attendance
          <span>Fast, Accurate, Intelligent.</span>
        </h1>
        <Helmet>
          <meta name="h1" content="Transform Daily Attendance - Fast, Accurate, Intelligent." />
        </Helmet>
        <h4>
          Simplify Operations, Enhance Productivity, and Reduce Cost with MyRollCall.Pro.
        </h4>
        <Helmet>
          <meta name="h4" content="Simplify Operations, Enhance Productivity, and Reduce Costs." />
        </Helmet>
        <h5>Save up to two months of curricular time annually with our proven methods.</h5>
        <Helmet>
          <meta name="h5" content="Save two months of curricular time annually with proven methods." />
        </Helmet>
        <div className="banner-btns">
          <button
            id="video_modal"
            className="play-video-btn"
            onClick={() => handleLinkClick("/video_play", "video_play")}
          >
            <span className="video-icon">
              <img className="videoIcon" src={videoIcon} alt="Play Icon" />
            </span>
            <h4>
              Watch How <br />
              It Works
            </h4>
          </button>
        </div>
        <div id="downarrow" className="crosicon">
          <img src={crosicon} alt="Cross Icon" />
        </div>
        <a href="#about" className="downarrow">
          <img src={downarrow} alt="Down Arrow" />
        </a>
      </div>

      <div id="modal" className={`modal-wrap video_modal_popup ${modalClass}`}>
        <div className="overlay" onClick={handleClickOutside}></div>
        <div className="modal-content">
          <div className="modal-header">
            <span className="close" onClick={handleClose}>
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M2 17L17 2" stroke="white" strokeWidth="3" strokeLinecap="round" />
                <path d="M2 2.02588L17 17.0259" stroke="white" strokeWidth="3" strokeLinecap="round" />
              </svg>
            </span>
            <h2>How it works</h2>
          </div>
          <div className="modal-body">
            <div className="video">
              <video ref={videoRef} className="mrcp_video" controls poster={videoCover}>
                <source src={MrcpVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <div className="modal-footer">
            <div className="col2">
              <div className="col6">
                <p>
                  Unlock the full potential of MyRollCallPro and transform the way your institution
                  handles attendance.
                </p>
              </div>
              <div className="col6">
                <div className="modal_buttons">
                  <Link to="/pricing" id="get_started" className="btn GetStarted">
                    View Pricing
                  </Link>
                  <Link id="demo_request" to="/demo_request" className="btn RequestaDemo">
                    Request a Demo
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Banner;
