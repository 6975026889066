/* global gtag */
import React from 'react'
import { Link,useLocation } from 'react-router-dom';
import appImg from '../../assets/images/app-image.png';
import './style.css';

function FooterCTA() {
  const location = useLocation(); // Get the current path

 
      
      const trackEvent = (elementId) => {
        const currentPath = location.pathname.replace('/', '') || 'home';
        const keyword = 'footer_banner'; // Use 'footer' for Footer navigation
        const eventName = `${currentPath}_${keyword}_${elementId}_view`;
        gtag('event', eventName);
      };
      
    
      // Trigger GA4 events when navigation links are clicked
      const handleLinkClick = (to, elementId) => {
        trackEvent(elementId);
      };
    return (
        <section className='section'>
            <div className='container'>
                <div className='footercta'>
                    <div className='app-img'><img src={appImg} alt=''/></div>
                    <div className='cta-right-content'>
                        <h2>Elevate Your Attendance Management Software Today</h2>
                        <p>Unlock the full potential of MyRollCall.Pro Your Attendance App</p>
                        <div className='cta-btns-grp'>
                            <Link id="demo_request" to="/demo_request" className="globel-button" onClick={() => handleLinkClick('/demo_request', 'demo_request')}>
                                <div className='txt-tooltip'>
                                    Experience the benefits firsthand and see how our solution fits your needs.
                                </div>
                                <span>Request a Demo</span>
                                <i><svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 6L16 6M16 6L11 1M16 6L11 11" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg></i>
                            </Link>
                            <button className="globel-button border-white">
                                <div className='txt-tooltip'>
                                    Join the revolution in attendance tracking system and start optimising your institution’s operations now.
                                </div>
                                <span className='pricing-span'>
                                    <Link id='pricing' to='/pricing' className='view-pricing-link' onClick={() => handleLinkClick('/pricing', 'pricing')}>View Pricing</Link>
                                </span>

                                <i><svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 6L16 6M16 6L11 1M16 6L11 11" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FooterCTA

