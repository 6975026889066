/* global gtag */
import React, { useState, useRef, useEffect } from 'react';
import './style.css'; // Assuming you're adding styles in this file
import logo from '../../assets/images/main-logo.svg';
import TopStrip from '../../top-strip';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const navRef = useRef(null);
  const location = useLocation(); // Get the current path
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target) && !event.target.closest('.mobile-menu-icon')) {
      closeMobileMenu();
    }
  };

  const handleScroll = () => {
    if (window.scrollY > 50) { // Adjust the scroll position as needed
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const trackEvent = (elementId) => {
    const currentPath = location.pathname.replace('/', '') || 'home';
    const keyword = 'nav'; // Use 'nav' for Header navigation
    const eventName = `${currentPath}_${keyword}_${elementId}_view`;
    gtag('event', eventName);
  };
  

  // Trigger GA4 events when navigation links are clicked
  const handleLinkClick = (to, elementId) => {
    trackEvent(elementId);
  };


  return (
    <header className={`header ${isSticky ? 'sticky' : ''}`}>
      <TopStrip />
      <div className="header-content">
        <div className="logo">
          <Link id='logo' to="/" onClick={() => handleLinkClick('/', 'home', 'logo')}>
            <img src={logo} alt="Logo" />
          </Link>          
        </div>

        <nav ref={navRef} className={`nav ${isMobileMenuOpen ? 'open' : ''}`}>
          <ul className="nav-links">
            <li>
              <Link
                id='home'
                to="/"
                className={location.pathname === '/' ? 'active' : ''}
                onClick={() => handleLinkClick('/home', 'home')}

              >
                Home
              </Link>
            </li>
            <li>
              <Link
                id='pricing'
                to="/pricing"
                className={location.pathname === '/pricing' ? 'active' : ''}
                onClick={() => handleLinkClick('/pricing', 'pricing')}

              >
                Pricing
              </Link>
            </li>
            <li>
              <Link
                id='blog'
                to="/blog"
                className={location.pathname === '/blog' ? 'active' : ''}
                onClick={() => handleLinkClick('/blog', 'blog')}
                
              >
                Blogs
              </Link>
            </li>
            <li className='bookademodesktop'>
              <Link
                id='get_app'
                to="/get_app"
                className={location.pathname === '/get_app' ? 'active' : ''}
                onClick={() => handleLinkClick('/get_app', 'get_app')}
              >
                Get App
              </Link>
            </li>
            <li>
              <Link
                id='demo_request'
                to="/demo_request"
                className={location.pathname === '/demo_request' ? 'active' : ''}
                onClick={() => handleLinkClick('/demo_request',  'demo_request')}
              >
                Request a Demo
              </Link>
            </li>      
          </ul>

          <Link
            id='get_app'
            to="/get_app"
            className="globel-button"
            onClick={() => handleLinkClick('/get_app', 'get_app')}
          >
            <span>Get App</span>
            <i>
              <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 6L16 6M16 6L11 1M16 6L11 11" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </i>
          </Link>

          <ul className="nav-links login_tab">
            <li>
              <Link
                id='login'
                to="https://uat-admin.myrollcall.pro/"
                className={location.pathname === 'https://uat-admin.myrollcall.pro/' ? 'active' : ''}
                onClick={() => handleLinkClick('https://uat-admin.myrollcall.pro/', 'login')}
              >
                Login
              </Link>
            </li>              
          </ul>
        </nav>

        <div className="mobile_demo_btn">
          <Link
            id='demo_request'
            to="/demo_request"
            className={location.pathname === '/demo_request' ? 'active' : ''}
            onClick={() => handleLinkClick('/demo_request','demo_request')}
          >
            Request a Demo
          </Link>
        </div>

        <div id='toggle_mobile_menu' className="mobile-menu-icon" onClick={toggleMobileMenu}>
          <span className="menu-icon">
            <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none">
              <g id="Menu / Menu_Alt_05">
                <path id="Vector" d="M5 17H13M5 12H19M11 7H19" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </g>
            </svg>
          </span>
        </div>
      </div>
    </header>
  );
};

export default Header;
