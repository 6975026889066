// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topStrip{
    background: rgb(4,6,64);
    background: linear-gradient(90deg, rgba(4,6,64,1) 0%, rgba(64,123,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#040640",endColorstr="#407bff",GradientType=1);
    text-align: center;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px 0;
}

.topStrip img{animation: attention 4s ease infinite;}
@keyframes attention {
    9% {
      transform: none;
    }
    12% {
      transform: scale(1.05);
    }
    16% {
      transform: scale(0.95);
    }
    20% {
      transform: scale(1.03);
    }
    24% {
      transform: scale(1);
    }
  }


  @media (max-width:767px){
    .mobile_demo_btn {
      display: flex  ;
      flex: 1 1;
      justify-content: right;
      padding: 0 7px 0 0;
  }
  .mobile_demo_btn a {
    font-size: 13px;
    color: #142B51;
    text-decoration: none;
    font-weight: 600;
}
  }

  @media (max-width:567px){
    .topStrip {
        font-size: 11px;
        height: 35px;
    }
    .topStrip img{
      max-width: 17px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/top-strip/style.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IAGvB,8EAA8E;IAC9E,gHAAgH;IAChH,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,SAAS;IACT,eAAe;AACnB;;AAEA,cAAc,qCAAqC,CAAC;AACpD;IACI;MACE,eAAe;IACjB;IACA;MACE,sBAAsB;IACxB;IACA;MACE,sBAAsB;IACxB;IACA;MACE,sBAAsB;IACxB;IACA;MACE,mBAAmB;IACrB;EACF;;;EAGA;IACE;MACE,eAAe;MACf,SAAO;MACP,sBAAsB;MACtB,kBAAkB;EACtB;EACA;IACE,eAAe;IACf,cAAc;IACd,qBAAqB;IACrB,gBAAgB;AACpB;EACE;;EAEA;IACE;QACI,eAAe;QACf,YAAY;IAChB;IACA;MACE,eAAe;IACjB;EACF","sourcesContent":[".topStrip{\n    background: rgb(4,6,64);\n    background: -moz-linear-gradient(90deg, rgba(4,6,64,1) 0%, rgba(64,123,255,1) 100%);\n    background: -webkit-linear-gradient(90deg, rgba(4,6,64,1) 0%, rgba(64,123,255,1) 100%);\n    background: linear-gradient(90deg, rgba(4,6,64,1) 0%, rgba(64,123,255,1) 100%);\n    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=\"#040640\",endColorstr=\"#407bff\",GradientType=1);\n    text-align: center;\n    color: #fff;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 10px;\n    padding: 10px 0;\n}\n\n.topStrip img{animation: attention 4s ease infinite;}\n@keyframes attention {\n    9% {\n      transform: none;\n    }\n    12% {\n      transform: scale(1.05);\n    }\n    16% {\n      transform: scale(0.95);\n    }\n    20% {\n      transform: scale(1.03);\n    }\n    24% {\n      transform: scale(1);\n    }\n  }\n\n\n  @media (max-width:767px){\n    .mobile_demo_btn {\n      display: flex  ;\n      flex: 1;\n      justify-content: right;\n      padding: 0 7px 0 0;\n  }\n  .mobile_demo_btn a {\n    font-size: 13px;\n    color: #142B51;\n    text-decoration: none;\n    font-weight: 600;\n}\n  }\n\n  @media (max-width:567px){\n    .topStrip {\n        font-size: 11px;\n        height: 35px;\n    }\n    .topStrip img{\n      max-width: 17px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
