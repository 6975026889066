import React from 'react'
import './style.css';
import callicon from '../../assets/images/call-icon.svg';
import emailicon from '../../assets/images/email-icon.svg';
import locationpinicon from '../../assets/images/location-pin-icon.svg';


function Contact() {
  return (
    <>
    <div className='innerpage-headerbanner contact-us'>
        <h1>We'd love to talk to you</h1>
        <p><i>Contact us today for a free consultation.</i></p>
    </div>

    <section className="address-cards-bar">
        <div className="container">
            <div className="row">
                <div className="col-md-4">
                    <div className="card-address" data-aos="fade-up" data-aos-delay="100">
                        <img src={callicon} alt="" />
                        <div className="address-card-details">
                            <h5>Call Us</h5>
                            <h4><a href="callto:7814572597">+91-78145-72597</a></h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card-address"  data-aos="fade-up" data-aos-delay="200">
                    <img src={emailicon} alt="" />
                        <div className="address-card-details">
                            <h5>Email Us</h5>
                            <h4><a href="mailto:Info@skyoindia.com">Info@skyoindia.com</a></h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card-address"  data-aos="fade-up" data-aos-delay="300">
                    <img src={locationpinicon} alt="" />
                        <div className="address-card-details">
                            <h5>Headquarters</h5>
                            <h4>Gurugram, India</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>



    <section className="contacts-us-form">
        <div className="container">
            <div className="row">
                <div className="col-md-4">
                    <div className="lets-work-together">
                        <h1>Let's Discuss Your Digital Future. Book a Free Consultation With Our Experts </h1>
                    </div>
                </div>
                <div className="col-md-8">
                    <form className="contact-form" action="" onsubmit="submitForm(event)">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <input type="text" className="form-control" id="fullName" placeholder="Name" required />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <input type="text" className="form-control" id="phone" placeholder="Phone Number (Optional)" />
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="mb-3">
                                    <input type="email" className="form-control" id="email" placeholder="Email" required />
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="mb-3">
                                    <textarea className="form-control" id="message" rows="3" placeholder="How can we help?" required></textarea>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <button id="contact_page_submit_form" type="submit" className="submit">Submit
                                    <div className="spinner-box">
                                        <div className="three-quarter-spinner"></div>
                                    </div>
                                </button>
                            </div>                            
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    
    
  </>
  )
}
export default Contact