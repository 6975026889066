import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './blogs.css';

const BlogDetails = () => {
  const { id } = useParams(); // Get the blog id from the URL
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    // Fetching blog data from JSON file and finding the specific blog by id
    fetch('/blogs.json')
      .then((response) => response.json())
      .then((data) => {
        const selectedBlog = data.find((blog) => blog.id === parseInt(id));
        setBlog(selectedBlog);
      })
      .catch((error) => console.error('Error fetching blog details:', error));
  }, [id]);

  if (!blog) {
    return <p>Loading blog...</p>;
  }

  // Extract content from all h tags (h1 to h6) and strong tags in blog.summary
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = blog.summary;

  // Extract all <h1> to <h6> text
  const hTagTexts = Array.from(tempDiv.querySelectorAll('h1, h2, h3, h4, h5, h6')).map((h) => h.innerText);

  // Extract all <strong> text
  const strongTexts = Array.from(tempDiv.querySelectorAll('strong')).map((strong) => strong.innerText);

  // Combine all texts for meta description and keywords
  const metaDescription = `${hTagTexts.join(', ')} - ${strongTexts.join(', ')}`.substring(0, 150); // Truncate if needed
  const metaKeywords = [...hTagTexts, ...strongTexts, blog.category || ''].join(', '); // Combine all texts and category

  return (
    <>
      {/* Helmet for dynamic meta tags */}
      <Helmet>
        <title>{blog.title} - MyRollCall.Pro</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />
        <meta property="og:title" content={blog.title} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={blog.detailimage} />
        <meta property="og:url" content={`https://www.myrollcall.pro/blog/${id}`} />
      </Helmet>

      <div className='blog-detail-header'>
        <div className='blog-detail-header-inner'>
          <div className='categorytag'>{blog.category}</div>
          <h1 className='blog-title-details'>{blog.title}</h1>
          <div className='date-and-author'>
            <div className='blog-date'>{new Date(blog.postDate).toLocaleDateString()}</div>
            <span></span>
            <div className='blog-author'>{blog.author}</div>
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='blog-details-image'>
          <img src={blog.detailimage} alt={blog.title} />
        </div>
        <div className='blog-details-content'>
          {/* Render blog summary */}
          <div dangerouslySetInnerHTML={{ __html: blog.summary }} />
        </div>
      </div>
    </>
  );
};

export default BlogDetails;
