/* global gtag */
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import footerLogo from "../../assets/images/RollCall.Logo-footer.svg";
import whatsappicon from "../../assets/images/whatsapp-icon.svg";
import "./style.css";
import FooterCTA from "../../components/footerCTA";

function Footer() {
  const location = useLocation(); // Get the current path
  const [isActive, setIsActive] = useState(false);

  const handleButtonClick = (e) => {
    e.stopPropagation();
    setIsActive(!isActive);
  };

  const handleDocumentClick = () => {
    if (isActive) {
      setIsActive(false);
    }
  };

  // Attach the document click event listener when the component mounts
  React.useEffect(() => {
    document.addEventListener("click", handleDocumentClick);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [isActive]);

  const trackEvent = (elementId) => {
    const currentPath = location.pathname.replace('/', '') || 'home';
    const keyword = 'footer'; // Use 'footer' for Footer navigation
    const eventName = `${currentPath}_${keyword}_${elementId}_view`;
    gtag('event', eventName);
  };
  

  // Trigger GA4 events when navigation links are clicked
  const handleLinkClick = (to, elementId) => {
    trackEvent(elementId);
  };

  return (
    <>
      <FooterCTA />
      <footer className="footer-section">
        <div className="container col2">
          <div className="footer-left col6">
            <Link to="/">
              <img className="footerlogo" src={footerLogo} alt=""/>
            </Link>
            <form className="newsletter-form">
              <h4>Stay Updated!</h4>
              <div className="form-wrap">
                <input type="text" placeholder="Your Email" />
                <button id="subscribe" className="subscribe"  onClick={() => handleLinkClick("/newsletter_signup", "newsletter_signup")}>
                  Subscribe
                </button>
              </div>
              <p>
                By subscribing, you consent to receive updates and
                communications from us.
              </p>
            </form>
          </div>
          <div className="footer-right col2 col6">
            <div className="col6">
              <div className="f_nav">
                <h3>Quick links</h3>
                <nav>
                  <ul>
                    <li>
                      <Link
                        id="pricing"
                        to="/pricing"
                        onClick={() => handleLinkClick("/pricing", "pricing")}
                      >
                        Pricing
                      </Link>
                    </li>
                    <li>
                      <Link id="blog" to="/blog">
                        Blog
                      </Link>
                    </li>
                    <li>
                      <Link
                        id="contact"
                        to="/contact"
                        onClick={() => handleLinkClick("/contact", "contact")}
                      >
                        Contact Us
                      </Link>
                    </li>
                    <li>
                      <Link
                        id="privacy-policy"
                        to="/privacy-policy"
                        onClick={() => handleLinkClick("/privacy", "privacy")}
                      >
                        Terms And Conditions
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="col6">
              <div className="address">
                <h3>Contact info</h3>
                <ul>
                  <li>
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23.75 12.8309C23.75 16.3886 19.3467 22.2807 16.7875 25.4048C15.8511 26.5478 14.1489 26.5478 13.2125 25.4048C10.6533 22.2807 6.25 16.3886 6.25 12.8309C6.25 7.81565 10.1675 3.75 15 3.75C19.8325 3.75 23.75 7.81565 23.75 12.8309Z"
                        stroke="#407BFF"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M18.2505 12.5001C18.2505 14.295 16.7954 15.7501 15.0005 15.7501C13.2056 15.7501 11.7505 14.295 11.7505 12.5001C11.7505 10.7051 13.2056 9.25007 15.0005 9.25007C16.7954 9.25007 18.2505 10.7051 18.2505 12.5001Z"
                        stroke="#407BFF"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>{" "}
                    Gurugram, Delhi NCR, Haryana - 122002
                  </li>
                  <li>
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.6333 19.3672C19.6781 28.4118 22.3283 26.2473 23.512 25.4018C23.7033 25.2932 28.0844 22.4702 25.3598 19.7461C19.0403 13.4262 20.3226 21.6408 14.3399 15.6592C8.35849 9.67641 16.5737 10.9596 10.2544 4.64034C7.52947 1.91547 4.70618 6.29681 4.5988 6.4869C3.75207 7.67063 1.58855 10.3225 10.6333 19.3672Z"
                        stroke="#407BFF"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>{" "}
                    <Link
                      id="mobileNumber"
                      href="#"
                      onClick={() =>
                        handleLinkClick(
                          "/contact_click_phone_7814",
                          "contact_click_phone_7814"
                        )
                      }
                    >
                      +91-78145-72597
                    </Link>
                  </li>
                  <li>
                    <svg
                      width="30"
                      height="31"
                      viewBox="0 0 30 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.20579 12.4867L9.26139 13.473L13.4838 17.4182C14.3376 18.215 15.6625 18.215 16.5163 17.4182L21.806 12.4867M7.22222 24.3889H22.7777C24.005 24.3889 24.9999 23.394 24.9999 22.1667V11.0556C24.9999 9.82829 24.005 8.83337 22.7777 8.83337H7.22222C5.99492 8.83337 5 9.82829 5 11.0556V22.1667C5 23.394 5.99492 24.3889 7.22222 24.3889Z"
                        stroke="#407BFF"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <Link
                      id="Email_trigger"
                      href="#"
                      onClick={() =>
                        handleLinkClick(
                          "/contact_click_email_info",
                          "contact_click_email_info"
                        )
                      }
                    >
                      Info@skyoindia.com
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-footer">
          <div className="container col2">
            <div className="col6">
              <div className="copyright">© 2024 Skaeo All Rights Reserved</div>
            </div>
            <div className="col6">
              <div className="FollowUson">
                <h4>Follow Us on </h4>
                <ul>
                  <li>
                    <a
                      id="twitter_icon"
                      onClick={() => handleLinkClick("/twitter", "twitter")}
                      href="https://x.com/Skaeo_"
                      target="blank_"
                    >
                      <svg
                        width="41"
                        height="41"
                        viewBox="0 0 41 41"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M26.2497 11.4604H29.3179L22.6165 19.118L30.5 29.5395H24.3288L19.4917 23.2206L13.9637 29.5395H10.8911L18.0576 21.3475L10.5 11.4604H16.8277L21.1953 17.2362L26.2497 11.4604ZM25.1719 27.7056H26.8711L15.902 13.1988H14.0767L25.1719 27.7056Z"
                          fill="white"
                        />
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a
                      id="LinkedIn_icon"
                      onClick={() => handleLinkClick("/linkedin", "linkedin")}
                      href="https://www.linkedin.com/company/skaeo-india/"
                      target="blank_"
                    >
                      <svg
                        width="42"
                        height="42"
                        viewBox="0 0 42 42"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.4768 30.9998H11.3304V17.6471H15.4768V30.9998ZM13.4013 15.8257C12.0754 15.8257 11 14.7275 11 13.4016C11 12.7647 11.253 12.1539 11.7033 11.7036C12.1537 11.2532 12.7645 11.0002 13.4013 11.0002C14.0382 11.0002 14.649 11.2532 15.0993 11.7036C15.5497 12.1539 15.8027 12.7647 15.8027 13.4016C15.8027 14.7275 14.7268 15.8257 13.4013 15.8257ZM30.9955 30.9998H26.858V24.4998C26.858 22.9507 26.8268 20.9641 24.7022 20.9641C22.5464 20.9641 22.2161 22.6471 22.2161 24.3882V30.9998H18.0741V17.6471H22.0509V19.4685H22.1089C22.6625 18.4194 24.0147 17.3123 26.0321 17.3123C30.2286 17.3123 31 20.0757 31 23.665V30.9998H30.9955Z"
                          fill="white"
                        />
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div id="center">
          <div id="draggable_button">
            <a
              id="floating_whatsapp"
              onClick={() => handleLinkClick("/whatapp", "whatapp")}
              href="https://wa.me/message/DPVXXI4NX42SJ1"
              target="blank_"
            >
              <img className="footerlogo" src={whatsappicon} alt=""/>
            </a>
          </div>
        </div>

        <div
          className={`linkedin-contect-floating ${isActive ? "active" : ""}`}
        >
          <button
            id="floating_linkedin_button"
            className="floating-linkedin-button"
            onClick={handleButtonClick}
          >
            <svg
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.390625 4.04431V24.9571C0.390625 26.9311 1.944 28.4844 3.91797 28.4844H24.8307C25.7969 28.4844 26.7201 28.0222 27.3086 27.435C27.9388 26.8047 28.3581 26.0066 28.3581 24.9571V4.04431C28.3581 2.07034 26.8047 0.516968 24.8307 0.516968H3.91797C2.8685 0.516968 2.07031 0.937562 1.44009 1.56644C0.851531 2.155 0.390625 3.07816 0.390625 4.04431ZM4.17059 6.81512C4.17059 5.47137 5.26306 4.29559 6.60681 4.29559C7.99222 4.29559 9.12634 5.47137 9.12634 6.81512C9.12634 8.20053 7.99222 9.293 6.60681 9.293C5.26306 9.293 4.17059 8.20053 4.17059 6.81512ZM10.931 24.0742V11.267C10.931 10.9727 11.2253 10.7214 11.4349 10.7214H15.0039C15.5078 10.7214 15.5078 11.31 15.5078 11.6876C16.5156 10.6797 17.8177 10.4271 19.1615 10.4271C22.4375 10.4271 24.5365 11.9805 24.5365 15.4245V24.0756C24.5365 24.3699 24.2838 24.6212 24.0326 24.6212H20.3372C20.043 24.6212 19.8333 24.3269 19.8333 24.0756V16.2644C19.8333 14.9623 19.4557 14.2487 17.9857 14.2487C16.138 14.2487 15.6758 15.4662 15.6758 17.1042V24.0756C15.6758 24.3699 15.3815 24.6212 15.0872 24.6212H11.4336C11.2239 24.6212 10.9297 24.3269 10.9297 24.0756L10.931 24.0742ZM4.25391 24.0742V11.267C4.25391 10.9727 4.54819 10.7214 4.75781 10.7214H8.41147C8.74741 10.7214 8.95703 10.931 8.95703 11.267V24.0742C8.95703 24.3685 8.70441 24.6198 8.41147 24.6198H4.75781C4.50519 24.6198 4.25391 24.3255 4.25391 24.0742Z"
                fill="white"
              />
            </svg>
          </button>
          <div className="linked-in-box">
            <h3>DM us on LinkedIn</h3>
            <a
              id="floating_linkedin_link_event"
              className="main-button"
              href="https://www.linkedin.com/company/skaeo-india/"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => handleLinkClick("/linkedin_dm", "linkedin_dm")}
            >
              LinkedIn
            </a>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
