import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import arrowprev from '../../assets/images/arrow-prev.png';
import arrownext from '../../assets/images/arrow-next.png';
import './blogs.css';

const BlogsList = () => {
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 9;

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch('/blogs.json');
        const data = await response.json();
        setBlogs(data);
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };

    fetchBlogs();
  }, []);

  const currentBlogs = useMemo(() => {
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    return blogs.slice(indexOfFirstPost, indexOfLastPost);
  }, [blogs, currentPage]);

  const nextPage = () => {
    if (currentPage < Math.ceil(blogs.length / postsPerPage)) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  // Generate meta description and keywords for the blog list page
  const metaDescription = blogs
    .map((blog) => blog.title)
    .join(', ')
    .substring(0, 150); // Truncate if needed
  const metaKeywords = blogs.map((blog) => blog.category).join(', ');

  return (
    <>
      {/* Helmet for SEO meta tags */}
      <Helmet>
        <title>Blog List - MyRollCall.Pro</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />
        <meta property="og:title" content="Our latest Blogs" />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:url" content="https://www.myrollcall.pro/blogs" />
      </Helmet>

      <div>
        <div className='innerpage-headerbanner'>
          <h1>Our latest <span>Blogs</span></h1>
        </div>

        <div className='container'>
          <div className='blog-list-wrapper'>
            <div className='blog-list-items'>
              {currentBlogs.map((blog) => (
                <div className='blog-items' key={blog.id}>
                  <Link id={blog.id} to={`/blog/${blog.id}`}>
                    <div className='blog-image'>
                      <img src={blog.image} alt={blog.title} />
                      <span className='hover-arrow'>
                      <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect x="1.5" y="1.5" width="87" height="87" rx="43.5" stroke="white" stroke-width="3"/>
                          <path d="M35.5286 32.314C35.3342 33.2215 35.561 33.3835 38.964 34.5178C41.4919 35.3605 43.9226 35.717 47.0338 35.717L51.4091 35.6846L29.0468 58.0468L30.9914 59.9914L53.2888 37.6939C53.3212 45.0508 53.7101 47.5787 54.5203 50.0742C55.6871 53.5095 55.8167 53.704 56.7566 53.5419C57.3075 53.4447 57.8909 53.1854 58.0529 53.0234C58.215 52.8614 57.8585 51.1437 57.1779 49.2315C56.4325 47.125 55.9787 44.3378 55.9463 42.1664C55.9139 40.1894 56.2704 37.6291 56.6917 36.43C57.1455 35.2632 58.0529 33.5131 58.8307 32.5409C60.0623 30.9204 60.0947 30.7584 59.1872 29.8509C58.2798 28.9435 58.1177 28.9759 56.4649 30.2398C55.525 30.9852 53.7749 31.8927 52.6082 32.3464C51.4091 32.7677 48.8487 33.1242 46.8718 33.0918C44.7004 33.0594 41.9132 32.6057 39.8066 31.8603C37.8945 31.1797 36.1768 30.8232 36.0148 30.9852C35.8203 31.1797 35.6258 31.7631 35.5286 32.314Z" fill="white"/>
                      </svg>


                      </span>
                    </div>
                    <div className='blog-category'>{blog.category}</div>
                    <div className='blog-title'>
                      <h2>{blog.title}</h2>
                    </div>
                    <div className='date-and-author'>
                      <div className='blog-date'>{new Date(blog.postDate).toLocaleDateString()}</div>
                      <span></span>
                      <div className='blog-author'>{blog.author}</div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>

            {/* Pagination Controls */}
            <div className='pagination'>
              <button onClick={prevPage} disabled={currentPage === 1}>
                <img src={arrowprev} alt='' /> Previous
              </button>
              <button onClick={nextPage} disabled={currentPage === Math.ceil(blogs.length / postsPerPage)}>
                Next <img src={arrownext} alt='' />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogsList;
