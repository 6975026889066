// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.google-recommended-button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: 428px;
    padding: 12px;
    font-size: 16px;
    font-weight: 500;
    color: #5f6368; 
    background-color: #ffffff;
    border: 1px solid #dadce0;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    transition: background-color 0.3s ease;
    gap: 8px;
}

.google-recommended-button img {
    margin-right: 8px;
    width: 20px;
    height: auto;
}


.google-recommended-button:hover {
    background-color: #f8f9fa;
}`, "",{"version":3,"sources":["webpack://./src/pages/pricing/googleLogin.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,WAAW;IACX,gBAAgB;IAChB,aAAa;IACb,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,yBAAyB;IACzB,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;IACf,0CAA0C;IAC1C,mBAAmB;IACnB,sCAAsC;IACtC,QAAQ;AACZ;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,YAAY;AAChB;;;AAGA;IACI,yBAAyB;AAC7B","sourcesContent":[".google-recommended-button {\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    width: 100%;\n    max-width: 428px;\n    padding: 12px;\n    font-size: 16px;\n    font-weight: 500;\n    color: #5f6368; \n    background-color: #ffffff;\n    border: 1px solid #dadce0;\n    border-radius: 8px;\n    cursor: pointer;\n    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);\n    margin-bottom: 20px;\n    transition: background-color 0.3s ease;\n    gap: 8px;\n}\n\n.google-recommended-button img {\n    margin-right: 8px;\n    width: 20px;\n    height: auto;\n}\n\n\n.google-recommended-button:hover {\n    background-color: #f8f9fa;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
